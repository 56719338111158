import React from "react";
import "./gives.scss";
import messagesImg from "../../assets/images/gives-messages.png";
import discordImg from "../../assets/images/gives-discord.png";
import twitterImg from "../../assets/images/gives-twitter.png";

const Gives = () => {
  return (
    <section className="gives">
      <div className="container gives__wrapper">
        <div className="gives__messages">
          <img src={messagesImg} alt="" />
          <img src={discordImg} alt="" className="gives__discord" />
        </div>
        <div className="gives__text">
          <h2>
            Track giveaways, announcement <br /> and tweets
          </h2>
        </div>
        <img src={twitterImg} alt="" className="gives__twitter" />
      </div>
      <div className="gives__gradientBg"></div>
    </section>
  );
};

export default Gives;
