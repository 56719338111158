import React from "react";
import "./faq.scss";

const faqData = [
  {
    id: 1,
    question: "Difference between paid and free?",
    answer:
      "In the paid version you are able view all the latest tweets from your favorite projects. Add more collections to your favorites. Gain access to the giveaways & announcements page.",
  },
  // {
  //   id: 2,
  //   question: "How much does is HoarderNest cost?",
  //   answer: "HoarderNest is pegged to 40USD.",
  // },
  {
    id: 3,
    question: "How long do we have access for?",
    answer: "We offer 1, 3 & 6 month plans.",
  },
  {
    id: 4,
    question: "Is it possible to auto-renew a subscription?",
    answer:
      "Because automatic recurring payments are not supported by the blockchain, then subscription renewal occurs only in manual mode.",
  },
  {
    id: 5,
    question: "What chains do you support?",
    answer: "Currently we only support the Ethereum blockchain.",
  },
  {
    id: 6,
    question: "Where do you get your data from?",
    answer: "We extract our data from Twitter, Discord and Opensea APIs.",
  },
  {
    id: 7,
    question: "I'm having an issue, how can I get in contact with the HoarderNest team?",
    answer: "You can reach us directly In our Discord support channel.",
  },
  {
    id: 8,
    question: "Is my subscription transferable?",
    answer: "No subscriptions are not transferable.",
  },
];

const FAQ = () => {
  return (
    <section className="container faq">
      <h2>FAQ</h2>
      <div className="faq__wrapper">
        {faqData.map((item, index) => (
          <article key={item.id} className="faq__item">
            <span>{(index + 1).toString().padStart(2, 0)}</span>
            <h3>{item.question}</h3>
            <div className="faq__mobileSpreader"></div>
            <p>{item.answer}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
