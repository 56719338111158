import React from "react";
import "./details.scss";
import detailsCounters from "../../assets/images/details-counters-2x.png";
import crossesImg from "../../assets/images/details-crosses.png";
import shapeImg from "../../assets/images/details-shape.png";

const Details = () => {
  return (
    <section className="details">
      <div className="container details__wrapper">
        <div className="details__image">
          <img src={crossesImg} alt="" />
          <img src={shapeImg} alt="" className="details__shapeImage" />
        </div>
        <div className="details__text">
          <h2>More Details</h2>
          <p>Giving you the best overview of the project and how they are performing.</p>
        </div>
        <img src={detailsCounters} alt="" className="details__counters" />
      </div>
      <div className="details__gradientBg"></div>
    </section>
  );
};

export default Details;
