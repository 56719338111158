import React from "react";
import Details from "./components/Details/Details";
import Explore from "./components/Explore/Explore";
import FAQ from "./components/FAQ/FAQ";
import Favorite from "./components/Favorite/Favorite";
import Footer from "./components/Footer/Footer";
import Gives from "./components/Gives/Gives";
import Header from "./components/Header/Header";
import Paid from "./components/Paid/Paid";
import Promo from "./components/Promo/Promo";
import Slider from "./components/Slider/Slider";

function App() {
  return (
    <main className="App">
      <Header />
      <Promo />
      <Gives />
      <Slider />
      <Favorite />
      <Explore />
      <Details />
      <Paid />
      <FAQ />
      <Footer />

      <div className="bottomGradient"></div>
    </main>
  );
}

export default App;
