import React from "react";
import "./footer.scss";
import logo from "../../assets/icons/logo-pale.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer__wrapper">
        <img src={logo} alt="" />
        <p>© 2022 All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
