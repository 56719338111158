import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import boredLogo from "../../assets/images/slider/bored-logo.png";
import boredBg from "../../assets/images/slider/bored-bg.jpg";
import azukiLogo from "../../assets/images/slider/azuki-logo.png";
import azukiBg from "../../assets/images/slider/azuki-bg.jpg";
import mutantLogo from "../../assets/images/slider/mutant-logo.png";
import mutantBg from "../../assets/images/slider/mutant-bg.jpg";
import cloneLogo from "../../assets/images/slider/clone-logo.png";
import cloneBg from "../../assets/images/slider/clone-bg.jpg";
import moonbirdsLogo from "../../assets/images/slider/moonbirds-logo.png";
import moonbirdsBg from "../../assets/images/slider/moonbirds-bg.jpg";
import gen2Logo from "../../assets/images/slider/gen2-logo.png";
import gen2Bg from "../../assets/images/slider/gen2-bg.jpg";
import goblinLogo from "../../assets/images/slider/goblin-logo.png";
import goblinBg from "../../assets/images/slider/goblin-bg.jpg";
import potatozLogo from "../../assets/images/slider/potatoz-logo.png";
import potatozBg from "../../assets/images/slider/potatoz-bg.jpg";
import etherLogo from "../../assets/icons/Ethereum.svg";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import { Autoplay } from "swiper";
import styles from "./slider.module.scss";
import { kFormatter } from "../../utils/utils";

const collectionData = [
  {
    id: 1,
    name: "Bored Ape Yacht Club",
    volumeTrade: 650000,
    gainPercent: 10,
    collectionSize: 10000,
    logo: boredLogo,
    bg: boredBg,
  },
  {
    id: 2,
    name: "CLONE X - X TAKASHI MURAKAMI",
    volumeTrade: 225100,
    gainPercent: 40,
    collectionSize: 19400,
    logo: cloneLogo,
    bg: cloneBg,
  },
  {
    id: 3,
    name: "Mutant Ape Yacht Club",
    volumeTrade: 442600,
    gainPercent: 44,
    collectionSize: 19400,
    logo: mutantLogo,
    bg: mutantBg,
  },
  {
    id: 4,
    name: "Moonbirds",
    volumeTrade: 166100,
    gainPercent: -11,
    collectionSize: 10000,
    logo: moonbirdsLogo,
    bg: moonbirdsBg,
  },
  {
    id: 5,
    name: "Azuki",
    volumeTrade: 257800,
    gainPercent: 44,
    collectionSize: 10000,
    logo: azukiLogo,
    bg: azukiBg,
  },
  {
    id: 6,
    name: "PG: GEN 2",
    volumeTrade: 6200,
    gainPercent: -34,
    collectionSize: 3300,
    logo: gen2Logo,
    bg: gen2Bg,
  },
  {
    id: 7,
    name: "goblintown.wtf",
    volumeTrade: 47100,
    gainPercent: -53,
    collectionSize: 10000,
    logo: goblinLogo,
    bg: goblinBg,
  },
  {
    id: 8,
    name: "The Potatoz",
    volumeTrade: 11000,
    gainPercent: -35,
    collectionSize: 10000,
    logo: potatozLogo,
    bg: potatozBg,
  },
];

const Slider = () => {
  return (
    <Swiper
      className={styles.slider}
      spaceBetween={16}
      slidesPerView={1.5}
      direction="horizontal"
      loop
      centeredSlides
      modules={[Autoplay]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        768: {
          slidesPerView: 4.5,
        },
        1600: {
          slidesPerView: 5.5,
        },
      }}
    >
      {collectionData.map(item => (
        <SwiperSlide key={item.id} className={styles.item}>
          <div className={styles.item__imageBlock}>
            <img src={item.bg} alt={item.name} className={styles.item__bg} />
            <img src={item.logo} alt={item.name} className={styles.item__logo} />
          </div>
          <h4>{item.name}</h4>
          <div className={styles.item__stats}>
            <div className={styles.item__statItem}>
              <p>Volume trade</p>
              <div className={styles.item__statNumbers}>
                <p>
                  <img src={etherLogo} alt="ether" /> <span>{kFormatter(item.volumeTrade)}</span>
                </p>
                <p
                  className={`${styles.item__gain} ${
                    item.gainPercent < 0 && styles.item__gain_negative
                  }`}
                >
                  {item.gainPercent > 0 ? (
                    <>
                      <img src={arrowUp} alt="up" /> {item.gainPercent}%
                    </>
                  ) : (
                    <>
                      <img src={arrowDown} alt="down" /> {Math.abs(item.gainPercent)}%
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.item__statItem}>
              <p>Collection size</p>
              <div className={styles.item__statNumbers}>
                <p>
                  <span>{kFormatter(item.collectionSize)}</span>&nbsp; items
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
