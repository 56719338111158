import React from "react";
import "./header.scss";
import logoImg from "../../assets/icons/logo.svg";
import menuImg from "../../assets/icons/menu.svg";
import cancelImg from "../../assets/icons/cancel.svg";

const Header = () => {
  const openMenu = () => {
    document.getElementById("mobileMenu").style.left = "0";
  };
  const closeMenu = () => {
    document.getElementById("mobileMenu").style.left = "100%";
  };

  const scrollToAnchor = anchor => {
    document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <header className="header">
        <a href="/" className="header__logo">
          <img src={logoImg} alt="logo" />
        </a>
        <nav className="header__right">
          <ul>
            {/* <li onClick={() => scrollToAnchor("pricing")}>Pricing</li> */}
            <li>
              <a
                href="https://twitter.com/hoardernest_?s=21&t=r2s9wTInggF2vP_dKBuhhA"
                target="_blank"
                rel="noreferrer"
              >
                Socials
              </a>
            </li>
            <li>
              <a
                href="https://hoardernest.gitbook.io/hoardernest/"
                target="_blank"
                rel="noreferrer"
              >
                White paper
              </a>
            </li>
          </ul>
          {/* <button className="btn header__openBtn">
          Open App
        </button> */}
          <button className="btn header__openBtn">Coming soon</button>
        </nav>
        <button className="btn header__openMenuBtn" onClick={openMenu}>
          <img src={menuImg} alt="menu" />
        </button>
      </header>

      <nav id="mobileMenu" className="mobileMenu">
        <div>
          <button className="btn mobileMenu__closeMenuBtn" onClick={closeMenu}>
            <img src={cancelImg} alt="menu" />
          </button>
          <ul>
            <li>
              <a
                href="https://twitter.com/hoardernest_?s=21&t=r2s9wTInggF2vP_dKBuhhA"
                target="_blank"
                rel="noreferrer"
              >
                Socials
              </a>
            </li>
            <li>
              <a
                href="https://hoardernest.gitbook.io/hoardernest/"
                target="_blank"
                rel="noreferrer"
              >
                White paper
              </a>
            </li>
            <li>
              <button className="btn header__openBtn">Coming soon</button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
