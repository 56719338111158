import React, { useEffect, useState } from "react";
import "./favorite.scss";
import favImg from "../../assets/images/favorite-img.png";
import mobileFavImg from "../../assets/images/favorite-img-mobile.png";
import Spline from "@splinetool/react-spline";
import heartSpline from "../../assets/heart.splinecode";

const Favorite = () => {
  const [heartObj, setHeartObj] = useState(null);
  const [spotlightObj, setSpotlightObj] = useState(null);

  const onLoad = spline => {
    // const obj = spline.findObjectById("9fe3a743-fe7a-4c55-a8cd-6a41c892846b"); // heart_obj (?)
    const heart = spline.findObjectById("f8e734d8-6842-4a66-a253-f591e7624f6b");
    const light = spline.findObjectById("2c4cc57f-343f-4214-917d-06aeddb7c6d0");
    setHeartObj(heart);
    setSpotlightObj(light);
  };

  // Control heart object
  useEffect(() => {
    if (!heartObj) {
      return;
    }

    spotlightObj.position.x = 0;

    heartObj.position.x = 0;
    heartObj.position.y = 0;
    heartObj.rotation.x = -0.1;

    // Scaling heart depends on resolution
    if (window.innerWidth < 1440 && window.innerWidth > 768) {
      heartObj.scale.x = 0.55;
      heartObj.scale.y = 0.55;
      heartObj.scale.z = 0.55;
    } else if (window.innerWidth < 767) {
      heartObj.scale.x = 0.7;
      heartObj.scale.y = 0.7;
      heartObj.scale.z = 0.7;
    } else {
      heartObj.scale.x = 0.9;
      heartObj.scale.y = 0.9;
      heartObj.scale.z = 0.9;
    }

    // Rotate it
    setInterval(() => {
      heartObj.rotation.y -= 0.05;
    }, 30);
  }, [heartObj, spotlightObj]);

  return (
    <section className="container">
      <div className="favorite">
        <h2>Add Favorite Collections</h2>
        <p>Providing you an efficient clutter-free experience</p>
        <img src={favImg} alt="" className="favorite__image" />
        <img src={mobileFavImg} alt="" className="favorite__mobileImage" />
        <div className="favorite__heart">
          <Spline scene={heartSpline} onLoad={onLoad} />
        </div>
      </div>
    </section>
  );
};

export default Favorite;
