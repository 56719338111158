import React from "react";
import "./explore.scss";
import screenImg from "../../assets/images/screen.jpg";

const Explore = () => {
  return (
    <section className="container explore">
      <div className="explore__descr">
        <div className="explore__text">
          <h3>Explore NFT collection</h3>
          <p>All the latest and hottest NFT collections, showing up to date key statistics.</p>
        </div>

        {/* <div className="explore__numbers">
          <p>
            <span>42 850</span>
            collections tracked
          </p>
          <p>
            <span>150</span>
            new collections <br />
            every day
          </p>
        </div> */}
      </div>

      <div className="explore__screen">
        <img src={screenImg} alt="App preview" />
      </div>

      <div className="explore__startBtn">
        {/* <button className="btn">
          Explore
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7H0V9Z"
              fill="#1A132E"
            />
          </svg>
        </button> */}
        <button className="btn">Coming soon</button>
      </div>
    </section>
  );
};

export default Explore;
